@import "~bootstrap/scss/bootstrap";

#root {
  height: 100vh;
}

h1 {
  font-size: 4rem;
}

.header {
  margin-bottom: 6rem;
}

h3 {
  a {
    color: lightsalmon;
    text-decoration: dotted underline;

    &:hover {
      color: red;
    }
  }

}
.inner {
  border: 1px solid black;
}

.outer {
  border: 1px solid red;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  height: 100vh;
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  // display: flex;
  justify-content: center;
  width: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
